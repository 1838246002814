.zola-ui.tooltip__container {
  width: 125px;
}
.zola-ui.tooltip__container.icon-tooltip {
  position: absolute;
  top: -40px;
  left: -10px;
}
.zola-ui .pop-in {
  animation: pop-in 0.5s ease-in 0s both;
}
@keyframes pop-in {
  0% {
    bottom: -10px;
    opacity: 0;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}
.zola-ui .tooltip {
  background-color: #0e0e0e;
  border-radius: 8px;
  color: #ffffff;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  position: relative;
  text-align: center;
}
.zola-ui .tooltip::before {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #0e0e0e;
  bottom: -8px;
  content: '';
  height: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  right: 0;
  width: 0;
}

.container___2B-ox {
  position: relative;
}

.primary___IuFl-,
.link___jEmBr {
  color: var(--text-primary, #0e0e0e);
  font-weight: 600;
  font-family: 'circular', helvetica, sans-serif;
  font-size: inherit;
  /* stylelint-disable-line sh-waqar/declaration-use-variable */
  line-height: normal;
  cursor: pointer;
  text-decoration: underline;
  letter-spacing: normal;
  font-stretch: normal;
  font-style: normal;
}
.primary___IuFl-:hover,
.link___jEmBr:hover {
  color: var(--text-secondary, #4a4a4a);
  text-decoration: underline;
}
.primary___IuFl-:active,
.link___jEmBr:active {
  color: var(--text-secondary, #4a4a4a);
  text-decoration: underline;
}
.newarrow___1f3LA {
  vertical-align: middle;
  padding-right: 0.2em;
  padding-bottom: 0.1em;
}
.newarrow___1f3LA::after {
  left: 0.2em;
  position: relative;
  margin-right: -1.5em;
}
.secondary___2CYKz {
  cursor: pointer;
  color: var(--interactive-primary, #0f5c82);
  text-decoration: underline;
}
.secondary___2CYKz:hover {
  color: var(--interactive-primary-hover, #4a4a4a);
}
.tertiary___2Rrna {
  cursor: pointer;
  color: #ffffff;
  text-decoration: underline;
}
.tertiary___2Rrna:hover {
  color: #dbdbdb;
}
.disabled___1Fazi {
  cursor: pointer;
  color: #b7b7b7;
  text-decoration: underline;
  cursor: auto;
}
.disabled___1Fazi:hover {
  color: #b7b7b7;
}
.disabled___1Fazi:hover,
.disabled___1Fazi:active,
.disabled___1Fazi:visited,
.disabled___1Fazi::after,
.disabled___1Fazi::before {
  color: #b7b7b7;
}
.active___3eeHN {
  text-decoration: underline;
}
.danger___2J3yZ {
  cursor: pointer;
  color: #c7305b;
  text-decoration: underline;
}
.danger___2J3yZ:hover {
  color: #cf4f74;
}
.black___3jqdM {
  color: var(--text-primary, #0e0e0e);
}
.inline___2KDmj {
  letter-spacing: normal;
}
.subtle___D4kGz {
  font-weight: 400;
}
.bold___1i8Dz {
  font-weight: 700;
}
.inherit___Zgbe_ {
  font-size: inherit;
}
.extraSmall___1C4ET {
  font-size: 12px;
}
.smaller___W_ZyD {
  font-size: 14px;
}
.sm___1v-g3,
.small___3NYTu {
  font-size: 16px;
}
.md___1cv5G,
.regular___2TzWM {
  font-size: 20px;
}
.large___1ssJ0,
.lg___PGecy {
  font-size: 24px;
}
.larger___36_PU,
.xl___2xM5h {
  font-size: 32px;
}
.semiHuge___2_D_N {
  font-size: 40px;
}
.huge___1Hac5 {
  font-size: 56px;
}
.button___2DAOp {
  outline: none;
  border: none;
  padding: 0;
  background-color: transparent;
}
.button___2DAOp:focus-visible {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: 1px;
}
.noUnderline___1ZI9B:not(:hover) {
  text-decoration: none;
}

